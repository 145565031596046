import $ from 'jquery';
import 'webpack-jquery-ui/autocomplete';
import 'webpack-jquery-ui/css';
import autosize from 'autosize';

export function initAutocomplete() {

    var wordList = [],
	cache      = {};

    $( "#queryArea" ).autocomplete({
            minLength: 1,
            autoFocus: true,
	    delay: 100,
            create: function( event, ui ) {
		$.ajax( {
                    url: "/raw_vocabulary",
                    dataType: "text",
                    success: function( data ) {
			wordList = data.split(',');
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
			console.error('Problem downloading vocabulary! Autocomplete mode will not work.\n' + thrownError)
                    }
		});
            },
            source: function( request, response ) {
		var cursorIndex = $('#queryArea')[0].selectionStart;
		var term_temp = request.term.substring(0, cursorIndex);
		var term = term_temp.substring(term_temp.lastIndexOf(" ") + 1);
		
		if (wordList === [] || term === " " || term === "") return false;
		
		if ( !(term in cache) ) {
                    var matcher = new RegExp('^' + $.ui.autocomplete.escapeRegex(term), "i");
		    
                    cache[term] = wordList.filter(function(word) {
			return matcher.test(word);
                    });
		}

		response( cache[term] );
            },
            focus: function(event) {
		// prevent value get inserted to textarea on focus on menu item
		return false;
            },
            select: function( event, ui ) {
		var cursorIndex = $('#queryArea')[0].selectionStart;
		var term_temp = this.value.substring(0, cursorIndex);
		var startpoint = term_temp.lastIndexOf(" ") + 1;

		var term = term_temp.substring(startpoint);

		this.value = this.value.substring(0, startpoint) + ui.item.value + " " + this.value.substring(startpoint + term.length);

		this.selectionEnd = startpoint + ui.item.value.length + 1;

		$( this ).autocomplete( "instance" ).previous = this.value;

		// resize textarea
		autosize.update(this);

		return false;
            }
	});

}
