import $ from 'jquery';
import dt from 'datatables.net-rowgroup';
import dt_bs4 from 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'

export function initTermsTable(){
    $('#terms').DataTable({
      lengthMenu: [[12, 25, 50, -1], [12, 25, 50, "All"]],
      dom: '<t<"termTable-bottom"li>>',
      columns: [
      { "width": "40%", "orderable": false},
      { "width": "25%", "type": "html-num-fmt", "orderSequence": ["desc"]},
      { "width": "25%", "type": "html-num-fmt", "orderSequence": ["desc"]},
      { "width": "10%", "orderable": false},
      { "width": "0%", "visible": false}
      ],
      autoWidth: false,
      order: [[2, 'desc']],
      orderFixed: [4, 'desc'],
      searching: false,
      rowGroup: {
        dataSrc: 4,
	className: 'termTable-group'
      }
    });

    document.getElementById('terms').classList.remove('d-none');
}
