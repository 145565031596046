import $ from 'jquery';

export function initPopovers(config){
  initQueryPopovers();

  if(config.publicationPopovers){
    initPublicationPopovers();
  }
}

/*
 * Popovers for recognized query terms.
 * These include: 
 *   - a glass brain
 *   - links to various sources (MeSH, brain atlas, wikipedia...)
 */

function initQueryPopovers(){
  // we set the current element as container in order to
  // keep showing the popover when we interact with it
  $('.termPopover').each(function() {
    let element = $(this);

    element.popover({ 
    html: true,
    trigger: 'hover', 
    container: element,
    boundary: 'viewport',
    delay: {'show': 350, 'hide': 300},
    title: function(){ return this.dataset.standardizedform },
    content: termGlassBrainFactory() 
    });
  });
}

function termGlassBrainFactory(termData){
  return function(){
    return `
      <ul class="popover-list">
        <img src="${termImageUrl(this.dataset.standardizedform)}" class="glassBrain" /> 
        <li>Search on <a href="https://en.wikipedia.org/wiki/${this.dataset.standardizedform}" rel="noopener noreferrer" target="_blank">Wikipedia</a></li>
        <li>Search on <a href="https://www.ncbi.nlm.nih.gov/mesh/?term=${this.dataset.standardizedform}" rel="noopener noreferrer" target="_blank">MeSH</a></li>
      </ul>
      `;
  }
}

function termImageUrl(standardizedForm){
  return `${$SCRIPT_ROOT}/glassbrains/${standardizedForm.replace(/ /g, '_')}.png`;
}

/*
 * Popovers for publications
 * (images only)
 */

export function initPublicationPopovers(){
  $('.publicationPopover').each(function() {
    let element = $(this);

    element.popover({ 
    html: true,
    trigger: 'hover', 
    boundary: 'viewport',
    placement: 'left',
    delay: {'show': 350, 'hide': 300},
    content: publicationGlassBrainFactory() 
    });
  });
}

function publicationGlassBrainFactory(pubData){
  return function(){
    return `<img src="${publicationImageUrl(this.dataset.pmid)}" class="glassBrain" />`;
  }
}

function publicationImageUrl(pmid){
  return `${$SCRIPT_ROOT}/article_glass_brains/map_for_pmid_${pmid}.png`;
}
