import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css';
import '../css/query_popovers.css';

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faDownload, faEdit, faQuestionCircle, faSmile, faBook } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
library.add(faDownload, faEdit, faQuestionCircle, faSmile, faGithub, faBook);
dom.watch();

export * from './query.js';
