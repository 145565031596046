import {initPopovers} from './popovers.js';
import {initAutocomplete} from './autocomplete.js';
import {initTermsTable} from './termsTable.js';
import autosize from 'autosize';
import $ from 'jquery';

/*
 * Initialization logic for the query page
 */
export function initQueryPage(config){
    initPopovers(config);

    if(config.clickToEdit){
	initClickToEdit(config);
    }

    if(config.autocomplete){
	initAutocomplete();
    }

    initTermsTable();

    autosize(document.querySelector('#queryArea'));

    let form = document.getElementById('queryForm');
    if(config.hideStaleSections){
       const staleIds = ['viewerSection', 'termSection', 'documentSection'];
       let ta = document.getElementById('queryArea');

       function setStaleSections(dirty){
	 staleIds.forEach((id) => {
	   let classes = document.getElementById(id).classList;
	   if(dirty){
	     classes.add('stale');
	   } else {
	     classes.remove('stale');
	   }
	 })       
       }

       function toggleStaleSections(){
	 let dirty = (ta.value !== ta.defaultValue);
	 setStaleSections(dirty);
       }

       function resetStaleSections(){
	 setStaleSections(false);
       }

       ta.addEventListener("input", toggleStaleSections);
       form.addEventListener("reset", resetStaleSections);
    }

    form.addEventListener("reset", () => {
      $('#queryTab a[href="#queryHighlight"]').tab('show');
    });

    // init tooltips
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })

    /* 
     * papaya regularly calls window.scrollTo(). Disallow this.
     */
    window.scrollTo = function(){}
}

/* click-to-edit
 *
 * Part of this could be implemented without JS (by
 * wrapping the highlighted text in an anchor) but
 * this would result in an unwanted "draggable" behavior
 */
function initClickToEdit(config){
    $('#queryHighlight').on('click', function(e){ 
	e.preventDefault();
	$('#queryTab a[href="#queryFormContainer"]').tab('show');
    });

    $('#queryTab a[href="#queryFormContainer"]').on('shown.bs.tab', function(e){
	autosize.update($('#queryArea'));
	$('#queryArea').select();
    });

    $('#queryTextHelp').text('Click to edit.');
    $('#queryEditHelp').text('Enter to submit; Shift+Enter to insert newline.');

    $('#queryArea').keydown(function(e){
      const ESCAPE = 27;
      let queryForm = document.getElementById('queryForm');

      if(e.keyCode == ESCAPE){
	if(!config.autocomplete || !$(this).autocomplete("instance").menu.active){
	  queryForm.reset();
	}
      }

      if ((e.keyCode == 13 || e.keyCode == 10) && !e.shiftKey) {
	e.preventDefault();
	if (config.autocomplete && $( this ).autocomplete( "instance" ).menu.active) {
	  return;
	}
	queryForm.submit();
	// tab pressed
      } else 	if (config.autocomplete && e.keyCode === 9 &&
	  $( this ).autocomplete( "instance" ).menu.active ) {
	e.preventDefault();
      }
    });
}
